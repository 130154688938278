<script setup lang="ts"></script>
<template>
  <div
    class="group grid h-auto cursor-pointer overflow-hidden rounded-[0.63rem]"
    @click="
      navigateTo(
        'https://www.techjobasia.com/job-campaigns/DBIxENl9RiqnLXjkCR9Pkw---Cyberport-Career-Fair-2025',
        {
          open: {
            target: '_blank'
          },
          external: true
        }
      )
    "
  >
    <div
      class="col-start-1 row-start-1 h-0 min-h-full w-full overflow-hidden rounded-[0.63rem]"
      style="background: linear-gradient(180deg, #daecf8 0%, #9ecee5 100%)"
    ></div>
    <div class="col-start-1 row-start-1 flex flex-col p-[1.25rem]">
      <div class="text-h4 font-bold text-[#125EB7]">Cyberport Career Fair 2025</div>
      <div class="h-[1.25rem] border-b border-b-[#125EB7]"></div>
      <div class="flex flex-row items-center justify-between text-primary">
        <div
          class="py-[0.62rem] text-[0.875rem] text-t6 font-medium leading-[1.125rem] text-[#125EB7] group-hover:underline"
        >
          Explore more tech jobs
        </div>
        <IconsFigmaArrowForwardOutlined class="h-[1.125rem] w-[1.125rem] shrink-0 text-[#125EB7]" />
      </div>
    </div>
  </div>
</template>
